<template>
  <v-layout column>
    <LabelTextField
      label="Tydzień poronienia"
      placeholder="Wpisz..."
      v-mask="'##'"
      :value.sync="value.week"
      :disabled="disabled"
      noMargins
      hide-details
    ></LabelTextField>
    <DefaultSubtitle class="my-3">Powód</DefaultSubtitle>
    <HTMLTextArea
      :value.sync="value.reason"
      :disabled="disabled"
      placeholder="Wpisz..."
      dense
      hideLabel
    ></HTMLTextArea>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    LabelTextField: () => import("@/components/LabelTextField"),
    HTMLTextArea: () => import("@/components/inputs/HTMLTextArea"),
  },
};
</script>